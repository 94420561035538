<template>
	<Modal name="edit-service">
		<template v-slot:header>
			<h1 class="title-search">{{$t("common.pack")}}</h1>
		</template>
		<template v-slot:content>
			<div>
				<form class="form">
					<div :class="`input-group form-group${ (missing.title && pack.title.length == 0 && getTitle().length == 0) ? ' required' : '' }`">
						<div class="input-group-prepend">
							<span class="input-group-text">{{$t("common.name")}}</span>
						</div>
						<input class="form-control" name="title" :placeholder="getTitle(true)" type="text" :value="getTitle()" @input="setTitle( $event.target.value )">
					</div>
					<div class="input-group form-group">
						<!--div id="checkvisible" class="checkbox checkbox-primary">
							<input id="visible" class=" styled d-none" name="visible" type="checkbox" v-model="menu_only">
							<label class="d-inline-block" for="visible">
								<div class="text-center checkbox-text">{{$t("administration.visible")}}</div>
							</label>
						</div-->
					</div>
					<div class="input-group form-group dependencies">
						<h2 class="title-search2">{{$t("modals.editservice.description")}}</h2>
					</div>
					<div id="description" class="input-group form-group">
						<textarea v-model="pack.description" @input="dataChanged = true" :placeholder="$t('common.optional')"/>
					</div>
					<div class="input-group form-group">
						<h2 class="title-search2">{{$t("common.onlyfor")}}</h2>
					</div>
					<div :class="`input-group form-group${ (missing.genders && !pack.female && !pack.male && !pack.child) ? ' required' : '' }`">
						<div id="gender-category" class="row">
							<div class="gender d-inline-block col-md-4">
								<div class="checkbox checkbox-primary">
									<input id="femme" class=" styled d-none" name="gender" type="checkbox" value="femme" v-model="pack.female" @input="dataChanged = true">
									<label class="d-inline-block" for="femme">
										<div class="checkbox-text checkboxes-aligned-mobile">{{$t("common.woman")}}</div>
									</label>
								</div>
							</div>
							<div class="gender d-inline-block col-md-4">
								<div class="checkbox checkbox-primary">
									<input id="homme" class=" styled d-none" name="gender" type="checkbox" value="homme" v-model="pack.male" @input="dataChanged = true">
									<label class="d-inline-block" for="homme">
										<div class="checkbox-text checkboxes-aligned-mobile">{{$t("common.man")}}</div>
									</label>
								</div>
							</div>
							<div class="gender d-inline-block col-md-4" v-if="$store.getters.salonAcceptsChildren()">
								<div class="checkbox checkbox-primary">
									<input id="enfant" class=" styled d-none" name="gender" type="checkbox" value="enfant" v-model="pack.child" @input="dataChanged = true">
									<label class="d-inline-block" for="enfant">
										<div class="checkbox-text checkboxes-aligned-mobile">{{$t("common.young")}}</div>
									</label>
								</div>
							</div>
						</div>
					</div>
					<div class="input-group form-group">
						<h2 class="title-search3">{{$t("common.services")}}</h2>
					</div>
				</form>
				<div id="prestations-cont">
					<!-- NOTE for next versions : allow manually ordering services (which will over-ride standard sorting) -->
					<!-- Draggable v-model="pack.services" :scroll-sensitivity="100" :force-fallback="true" v-on:start="drag=true" v-on:end="drag=false" class="ui-sortable" handle=".sortable" -->
					<div class="d-flex d-justify-content-center btn-depend">
						<ServiceButton
							v-for="service in servicesResolved"
							:key="service.id" :id="'dependency-' + service.id"
							:service="service"
							@remove="dataChanged = true; pack.services.splice( pack.services.indexOf( service.id ), 1 )"
							:hide="pack.services"
						/>
					</div>
					<!-- /Draggable -->
					<ServiceButton
						@selected="dataChanged = true; pack.services.push( $event.id ); pack.services.sort( ( a, b ) => this.$store.state.dependencies.find(s => s.id == a).priority - this.$store.state.dependencies.find(s => s.id == b).priority )"
						:hide="pack.services"
					/>
				</div>
				<div id="prices-title" class="input-group form-group">
					<h2 class="title-search2">
						{{ $t("administration.price") }}
					</h2>
				</div>
				<div id="tarifs-wrapper" class="input-group form-group">
					<table id="pack-prices">
						<thead>
							<tr>
								<th v-if="$store.getters.salonPrestationsHasLength()">{{ $t("common.hair") }}</th>
								<th>{{ $t("common.wom") }}</th>
								<th class="desktop">{{ $t("common.men") }}</th>
								<th class="desktop" v-if="$store.getters.salonAcceptsChildren()">{{ $t("common.youth") }}</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td class="length" v-if="$store.getters.salonPrestationsHasLength()">{{ $t("data.hairlength.short") }}</td>
								<td>
									<div class="price" data-currency="€">
										<ValidationInput
											class="form-control"
											type="positiveReal" nullable
											v-model="pack.prices.female_short"
											@input="dataChanged = true"
											@valid="valid['prices.female_short'] = $event"
											:disabled="!pack.female"
											:placeholder="calculatePrice(domain == 'coachs' ? 'female' : 'female_short')"
										/>
									</div>
								</td>
								<td class="desktop">
									<div class="price" data-currency="€">
										<ValidationInput
											class="form-control"
											type="positiveReal" nullable
											v-model="pack.prices.male_short"
											@input="dataChanged = true"
											@valid="valid['prices.male_short'] = $event"
											:disabled="!pack.male"
											:placeholder="calculatePrice(domain == 'coachs' ? 'male' : 'male_short')"
										/>
									</div>
								</td>
								<td class="desktop" :rowspan="$store.getters.salonPrestationsHasLength() ? 3 : 1" v-if="$store.getters.salonAcceptsChildren()">
									<div class="price" data-currency="€">
										<ValidationInput
											class="form-control"
											type="positiveReal" nullable
											v-model="pack.prices.child"
											@input="dataChanged = true"
											@valid="valid['prices.child'] = $event"
											:disabled="!pack.child"
											:placeholder="calculatePrice('child')"
										/>
									</div>
								</td>
							</tr>
							<tr v-if="$store.getters.salonPrestationsHasLength()">
								<td class="length">{{ $t("data.hairlength.half") }}</td>
								<td>
									<div class="price" data-currency="€">
										<ValidationInput
											class="form-control"
											type="positiveReal" nullable
											v-model="pack.prices.female_half"
											@input="dataChanged = true"
											@valid="valid['prices.female_half'] = $event"
											:disabled="!pack.female"
											:placeholder="calculatePrice('female_half')"
										/>
									</div>
								</td>
								<td  class="desktop" />
							</tr>
							<tr v-if="$store.getters.salonPrestationsHasLength()">
								<td class="length">{{ $t("data.hairlength.long") }}</td>
								<td>
									<div class="price" data-currency="€">
										<ValidationInput
											class="form-control"
											type="positiveReal" nullable
											v-model="pack.prices.female_long"
											@input="dataChanged = true"
											@valid="valid['prices.female_long'] = $event"
											:disabled="!pack.female"
											:placeholder="calculatePrice('female_long')"
										/>
									</div>
								</td>
								<td  class="desktop">
									<div class="price" data-currency="€">
										<ValidationInput
											class="form-control"
											type="positiveReal" nullable
											v-model="pack.prices.male_long"
											@input="dataChanged = true"
											@valid="valid['prices.male_long'] = $event"
											:disabled="!pack.male"
											:placeholder="calculatePrice('male_long')"
										/>
									</div>
								</td>
							</tr>
							<tr class= "mobile thead">
								<th v-if="$store.getters.salonPrestationsHasLength()">{{ $t("common.hair") }}</th>
								<th>{{ $t("common.men") }}</th>
							</tr>
							<tr class = "mobile">
								<td class="length" v-if="$store.getters.salonPrestationsHasLength()">{{ $t("data.hairlength.short") }}</td>
								<td>
									<div class="price" data-currency="€">
										<ValidationInput
											class="form-control"
											type="positiveReal" nullable
											v-model="pack.prices.male_short"
											@input="dataChanged = true"
											@valid="valid['prices.male_short'] = $event"
											:disabled="!pack.male"
											:placeholder="calculatePrice(domain == 'coachs' ? 'male' : 'male_short')"
										/>
									</div>
								</td>
							</tr>
							<tr class = "mobile" v-if="$store.getters.salonPrestationsHasLength()">
								<td class="length">{{ $t("data.hairlength.long") }}</td>
								<td>
									<div class="price" data-currency="€">
										<ValidationInput
											class="form-control"
											type="positiveReal" nullable
											v-model="pack.prices.male_long"
											@input="dataChanged = true"
											@valid="valid['prices.male_long'] = $event"
											:disabled="!pack.male"
											:placeholder="calculatePrice('male_long')"
										/>
									</div>
								</td>
							</tr>
							<tr class= "mobile thead" v-if="$store.getters.salonAcceptsChildren()">
								<th v-if="$store.getters.salonPrestationsHasLength()">{{ $t("common.hair") }}</th>
								<th>{{ $t("common.young") }}</th>
							</tr>
							<tr class="mobile" v-if="$store.getters.salonAcceptsChildren()">
								<td class="length"></td>
								<td>
									<div class="price" data-currency="€">
										<ValidationInput
											class="form-control"
											type="positiveReal" nullable
											v-model="pack.prices.child"
											@input="dataChanged = true"
											@valid="valid['prices.child'] = $event"
											:disabled="!pack.child"
											:placeholder="calculatePrice('child')"
										/>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div v-if="!$store.getters.salonPrestationsHavePauses()" id="times-wrapper" class="input-group form-group">
					<table id="pack-times">
						<thead>
							<tr>
								<th v-if="$store.getters.salonPrestationsHasLength()">{{ $t("common.hair") }}</th>
								<th>{{ $t("common.wom") }}</th>
								<th class="desktop">{{ $t("common.men") }}</th>
								<th class="desktop" v-if="$store.getters.salonAcceptsChildren()">{{ $t("common.youth") }}</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td class="length" v-if="$store.getters.salonPrestationsHasLength()">{{ $t("data.hairlength.short") }}</td>
								<td>
									<div class="duration">
										<ValidationInput
											class="form-control"
											type="positiveReal" nullable
											v-model="pack.durations.female_short"
											@input="dataChanged = true"
											@valid="valid['durations.female_short'] = $event"
											:disabled="!pack.female"
											:placeholder="calculateDuration(domain == 'coachs' ? 'female' : 'female_short')"
										/>
									</div>
								</td>
								<td class="desktop">
									<div class="duration">
										<ValidationInput
											class="form-control"
											type="positiveReal" nullable
											v-model="pack.durations.male_short"
											@input="dataChanged = true"
											@valid="valid['durations.male_short'] = $event"
											:disabled="!pack.male"
											:placeholder="calculateDuration(domain == 'coachs' ? 'male' : 'male_short')"
										/>
									</div>
								</td>
								<td class="desktop" :rowspan="$store.getters.salonPrestationsHasLength() ? 3 : 1" v-if="$store.getters.salonAcceptsChildren()">
									<div class="duration">
										<ValidationInput
											class="form-control"
											type="positiveReal" nullable
											v-model="pack.durations.child"
											@input="dataChanged = true"
											@valid="valid['durations.child'] = $event"
											:disabled="!pack.child"
											:placeholder="calculateDuration('child')"
										/>
									</div>
								</td>
							</tr>
							<tr v-if="$store.getters.salonPrestationsHasLength()">
								<td class="length">{{ $t("data.hairlength.half") }}</td>
								<td>
									<div class="duration">
										<ValidationInput
											class="form-control"
											type="positiveReal" nullable
											v-model="pack.durations.female_half"
											@input="dataChanged = true"
											@valid="valid['durations.female_half'] = $event"
											:disabled="!pack.female"
											:placeholder="calculateDuration('female_half')"
										/>
									</div>
								</td>
								<td  class="desktop" />
							</tr>
							<tr v-if="$store.getters.salonPrestationsHasLength()">
								<td class="length">{{ $t("data.hairlength.long") }}</td>
								<td>
									<div class="duration">
										<ValidationInput
											class="form-control"
											type="positiveReal" nullable
											v-model="pack.durations.female_long"
											@input="dataChanged = true"
											@valid="valid['durations.female_long'] = $event"
											:disabled="!pack.female"
											:placeholder="calculateDuration('female_long')"
										/>
									</div>
								</td>
								<td  class="desktop">
									<div class="duration">
										<ValidationInput
											class="form-control"
											type="positiveReal" nullable
											v-model="pack.durations.male_long"
											@input="dataChanged = true"
											@valid="valid['durations.male_long'] = $event"
											:disabled="!pack.male"
											:placeholder="calculateDuration('male_long')"
										/>
									</div>
								</td>
							</tr>
							<tr class= "mobile thead">
								<th v-if="$store.getters.salonPrestationsHasLength()">{{ $t("common.hair") }}</th>
								<th>{{ $t("common.men") }}</th>
							</tr>
							<tr class = "mobile">
								<td class="length" v-if="$store.getters.salonPrestationsHasLength()">{{ $t("data.hairlength.short") }}</td>
								<td>
									<div class="duration">
										<ValidationInput
											class="form-control"
											type="positiveReal" nullable
											v-model="pack.durations.male_short"
											@input="dataChanged = true"
											@valid="valid['durations.male_short'] = $event"
											:disabled="!pack.male"
											:placeholder="calculateDuration(domain == 'coachs' ? 'male' : 'male_short')"
										/>
									</div>
								</td>
							</tr>
							<tr class = "mobile" v-if="$store.getters.salonPrestationsHasLength()">
								<td class="length">{{ $t("data.hairlength.long") }}</td>
								<td>
									<div class="duration">
										<ValidationInput
											class="form-control"
											type="positiveReal" nullable
											v-model="pack.durations.male_long"
											@input="dataChanged = true"
											@valid="valid['durations.male_long'] = $event"
											:disabled="!pack.male"
											:placeholder="calculateDuration('male_long')"
										/>
									</div>
								</td>
							</tr>
							<tr class= "mobile thead" v-if="$store.getters.salonAcceptsChildren()">
								<th v-if="$store.getters.salonPrestationsHasLength()">{{ $t("common.hair") }}</th>
								<th>{{ $t("common.young") }}</th>
							</tr>
							<tr class="mobile" v-if="$store.getters.salonAcceptsChildren()">
								<td class="length"></td>
								<td>
									<div class="duration">
										<ValidationInput
											class="form-control"
											type="positiveReal" nullable
											v-model="pack.durations.child"
											@input="dataChanged = true"
											@valid="valid['durations.child'] = $event"
											:disabled="!pack.child"
											:placeholder="calculateDuration('child')"
										/>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="input-group form-group dependencies">
					<h2 class="title-search2">{{$t("modals.editservice.message")}}</h2>
				</div>
				<div id="message" class="input-group form-group">
					<div class="text-muted">{{ $t('modals.editservice.questiontext') }}</div>
					<textarea v-model="pack.message" @input="dataChanged = true" :placeholder="$t('modals.editservice.question')"/>
				</div>
			</div>
		</template>
		<template v-slot:buttons>
			<StatusButton v-if="prefillPack && Object.keys(prefillPack).length > 0" id="delete" type="danger" alert="modal" v-on:click="showDeleteConfirm = $event"><i class="fas fa-trash-alt"></i></StatusButton>
			<StatusButton id="validate" type="primary" alert="modal" @click="save( $event, true )"><i class="fas fa-check"></i></StatusButton>
			<StatusButton class="desktop" id="apply" type="secondary" alert="modal" @click="save( $event, false )">{{$t("common.apply")}}</StatusButton>
			<button id="cancel" class="btn btn-gray" @click="$emit('close')" type="button">{{$t( dataChanged ? "common.cancel" : "common.close")}}</button>
			<Confirm v-if="showDeleteConfirm" ref="deleteConfirm">
				{{ $t('pack.confirmdelete') }}
			</Confirm>
		</template>
	</Modal>
</template>

<script>
	import Draggable from 'vuedraggable'
	import Modal from '../components/Modal.vue'
	import ServiceButton from '../components/ServiceButton.vue'
	import StatusButton from '../components/StatusButton.vue'
	import Confirm from "../modals/Confirm.vue"

	export default {
		components: {
			Modal,
			Confirm,
			Draggable,
			ServiceButton,
			StatusButton
		},
		props: [
			"data"
		],
		data() {
			return {
				valid: {},
				pack: {
					female: false,
					male: false,
					child: false,
					title: "",
					genders: "",
					services: [],
					prices: {
						female_short: null,
						female_half: null,
						female_long: null,
						male_short: null,
						male_long: null,
						child: null
					},
					durations: {
						female_short: null,
						female_half: null,
						female_long: null,
						male_short: null,
						male_long: null,
						child: null
					},
					menu_only: false,
					description: null,
					message: null
				},
				missing: {
					title: false,
					genders: false,
				},
				prefillPack: this.data,
				dataChanged: false,
				showDeleteConfirm: false,
				preventValidation: false
			}
		},
		computed: {
			domain() {
				return this.$store.state.domain
			},
			menu_only: {
				get() {
					return !this.pack.menu_only
				},
				set( v ) {
					this.pack.menu_only = !v
				}
			},
			servicesResolved() {
				return this.pack.services.map(id => this.$store.state.services.find(s => s.id == id))
			}
		},
		watch: {
			showDeleteConfirm( callback ) {
				if ( callback !== false ) {
					this.$nextTick( () => {
						this.$refs.deleteConfirm.$on( "confirm", () => {
							this.$refs.deleteConfirm.$off( 'confirm' )
							this.$refs.deleteConfirm.$off( 'close' )
							this.showDeleteConfirm = false
							this.deletePack( callback )
						})
						this.$refs.deleteConfirm.$on( "close", () => {
							this.$refs.deleteConfirm.$off( 'confirm' )
							this.$refs.deleteConfirm.$off( 'close' )
							this.showDeleteConfirm = false
							callback( -1 )
						})
					})
				}
			}
		},
		methods: {
			calculateDuration( gender_hair ) {
				/*
				console.log( "gender_hair", gender_hair, this.prefillPack )
				if ( !this.prefillPack || !this.prefillPack.computedServices || this.prefillPack.computedServices.length == 0 ) {
					return ""
				}
				return this.prefillPack.computedServices.reduce( (accum, s) => accum + parseFloat(s && s[gender_hair] ? (s[gender_hair][1] || 0) : 0), 0 )
				*/
				return this.pack.services.reduce( (accum, s) => {
					s = this.$store.getters.getService(s)
					return accum + parseFloat(s && s[gender_hair] ? (s[gender_hair][1] || 0) : 0)
				}, 0 )
			},
			calculatePrice( gender_hair ) {
				return this.pack.services.reduce( (accum, s) => {
					s = this.$store.getters.getService(s)
					return accum + parseFloat(s && s[gender_hair] ? (s[gender_hair][0] || 0) : 0)
				}, 0 )
			},
			setTitle( value ) {
				this.pack.title = value
				this.dataChanged = true
			},
			getTitle( force_computed ) {
				let ret = ""
				if ( !force_computed && this.pack.title ) {
					ret = this.pack.title
				} else if ( this.servicesResolved ) {
					ret = this.servicesResolved.reduce( ( a, b ) => a + ( a != "" ? " - " : "" ) + b.title, "" )
				}
				return ret
			},
			deletePack( callback ) {
				this.$api.formula.delete( this.prefillPack.id ).then( response => {
					callback( true )
					setTimeout( () => this.$emit( 'close' ), 1250 )
				}).catch( error => callback( false, error.response.data.error ) )
			},
			save( callback, closeAfter ) {
				console.log(Object.values(this.valid))
				if ( this.preventValidation || !Object.values(this.valid).every(val => val) ) {
					callback( -1 )
					return
				}
				this.preventValidation = true

				let patch = {}
			//	Object.keys( this.prefillPack ).forEach( key => {
				Object.keys( this.pack ).forEach( key => {
					if ( key !== "services" && key !== "female" && key !== "male" && key !== "child" && key !== "prices" && key !== "durations" && this.pack[key] !== this.data[key] ) {
						patch[key] = this.pack[key]
					}
				})
				Object.keys(this.pack.prices).forEach(key => {
					this.pack.prices[key] = this.pack.prices[key] || null
				})
				Object.keys(this.pack.durations).forEach(key => {
					this.pack.durations[key] = this.pack.durations[key] || null
				})
				console.log( this.pack.prices )
				const data_cmp = { ...this.data }
				if ( this.$store.state.domain == "coachs" ) {
					data_cmp.male_short = data_cmp.male
					data_cmp.female_short = data_cmp.female
					data_cmp.dur_male_short = data_cmp.dur_male
					data_cmp.dur_female_short = data_cmp.dur_female
				}
				Object.keys( this.pack.prices ).forEach( key => {
					console.log( "comp", key, this.pack.prices[key], data_cmp[key] )
					if ( this.pack.prices[key] != data_cmp[key] ) {
						patch[key] = this.pack.prices[key]
						if ( patch[key] === null || patch[key] === undefined ) {
							patch[key] = ""
						}
					}
				})
				Object.keys( this.pack.durations ).forEach( key => {
					if ( this.pack.durations[key] != data_cmp["dur_" + key] ) {
						patch["dur_" + key] = this.pack.durations[key]
						if ( patch["dur_" + key] === null || patch["dur_" + key] === undefined ) {
							patch["dur_" + key] = ""
						}
					}
				})
				console.log( "patch", patch )
				if ( this.$store.state.domain == "coachs" ) {
					if ( patch.male_short !== null && patch.make_short !== undefined ) {
						patch.male = patch.male_short
						if ( patch.male === "" ) {
							patch.male = null
						}
					}
					if ( patch.female_short !== null && patch.female_short !== undefined ) {
						patch.female = patch.female_short
						if ( patch.female === "" ) {
							patch.female = null
						}
					}
					if ( patch.dur_male_short !== null && patch.dur_male_short !== undefined ) {
						patch.dur_male = patch.dur_male_short
						if ( patch.dur_male === "" ) {
							patch.dur_male = null
						}
					}
					if ( patch.dur_female_short !== null && patch.dur_female_short !== undefined ) {
						patch.dur_female = patch.dur_female_short
						if ( patch.dur_female === "" ) {
							patch.dur_female = null
						}
					}
					delete patch.male_short
					delete patch.male_half
					delete patch.male_long
					delete patch.female_short
					delete patch.female_half
					delete patch.female_long
					delete patch.dur_male_short
					delete patch.dur_male_half
					delete patch.dur_male_long
					delete patch.dur_female_short
					delete patch.dur_female_half
					delete patch.dur_female_long
				}
				let genders = [];
				[ "female", "male", "child" ].forEach( g => { if ( this.pack[g] ) { genders.push( g ) } } )
				patch.genders = genders.join( "," )
				patch.title = patch.title || this.getTitle()
				patch.services = []
				for ( var i = 0; i < this.pack.services.length; i++ ) {
					patch.services.push( this.pack.services[i] )
				}

				let error = false;
				[ "title", "genders" ].forEach( field => {
					if ( !patch[field] || patch[field].length == 0 ) {
						this.missing[field] = true
						error = true
						callback( false )
					}
				})
				if ( patch.services.length < 2 ) {
					callback( false, this.$t( "errors.min2services" ) )
					error = true
				}
				if ( error ) {
					this.preventValidation = false
					return
				}

				if ( this.dataChanged && Object.keys( patch ).length > 0 ) {
					if ( this.prefillPack && this.prefillPack.id > 0 ) {
						console.log( "final patch", patch )
						this.$api.formula.patch( this.prefillPack.id, patch ).then( response => {
							callback( true )
							this.prefillPack = JSON.parse( JSON.stringify( this.pack ) )
							this.dataChanged = false
							if ( closeAfter ) {
								setTimeout( () => { this.preventValidation = false, this.$emit( 'close' ) }, 1000 )
							} else {
								this.preventValidation = false
							}
						}).catch( error => {
							console.log( error )
							this.preventValidation = false
							callback( false, error.response.data.error )
						})
					} else {
						patch.show_website = true
						this.$api.formulas.post( patch ).then( response => {
							callback( true )
							this.prefillPack = JSON.parse( JSON.stringify( this.pack ) )
							this.prefillPack.id = response.data[0].id
							this.dataChanged = false
							if ( closeAfter ) {
								setTimeout( () => { this.preventValidation = false, this.$emit( 'close' ) }, 1000 )
							} else {
								this.preventValidation = false
							}
						}).catch( error => {
							console.log( error )
							this.preventValidation = false
							callback( false, error.response.data.error )
						})
					}
				} else if ( closeAfter ) {
					this.$emit( 'close' )
				} else {
					callback( -1 )
				}
				this.preventValidation = false
			}
		},
		created() {
			const prefill = { ...this.prefillPack }
			if ( prefill && this.$store.state.domain == "coachs" ) {
				prefill.female_short = prefill.female
				prefill.male_short = prefill.male
				prefill.dur_female_short = prefill.dur_female
				prefill.dur_male_short = prefill.dur_male
			}
			Object.keys( prefill ).forEach( key => {
				if ( key !== "services" ) {
					if ( Object.keys(this.pack.prices).includes(key) ) {
						this.pack.prices[key] = prefill[key]
					} else if ( key.indexOf( "dur_" ) == 0 ) {
						this.pack.durations[key.substr(4)] = prefill[key]
					} else {
						this.pack[key] = prefill[key]
					}
				}
			})
			if ( prefill.services ) {
				for ( var i = 0; i < prefill.services.length; i++ ) {
					this.pack.services[i] = prefill.services[i]
				}
			}
			if ( this.pack.genders ) {
				let genders = this.pack.genders.split( "," )
				this.pack.female = genders.includes( "female" )
				this.pack.male = genders.includes( "male" )
				this.pack.child = genders.includes( "child" )
			}
		},
		mounted() {
			this.$nextTick( () => {
				if ( !this.prefillPack || Object.keys( this.prefillPack ).length == 0 ) {
					this.dataChanged = true
				} else {
					this.dataChanged = false
				}
			})
		}
	}
</script>

<style scoped lang="scss" src="../css/modals/editpack.scss"/>
