<template>
	<div>
		<table id="forfaits" class="mobile-table table-sticky">
			<thead>
				<tr>
					<th rowspan="2">{{$t("common.name")}}</th>
					<th rowspan="2" v-if="$store.getters.salonPrestationsHasLength()">
						{{ $t("common.hair") }}
					</th>
					<th :colspan="$store.getters.salonAcceptsChildren() ? 3 : 2">
						{{ $t("common.price") }}
					</th>
					<th :colspan="$store.getters.salonAcceptsChildren() ? 3 : 2">
						{{ $t("common.duration") }}
					</th>
					<th rowspan="2">{{$t("common.onlyfor")}}</th>
					<th rowspan="2">{{$t("common.services")}}</th>
					<th rowspan="2">{{$t("common.action")}}</th>
				</tr>
				<tr>
					<th colspan="1">
						{{ $t("common.wom") }}
					</th>
					<th colspan="1">
						{{ $t("common.men") }}
					</th>
					<th colspan="1" rowspan="1" v-if="$store.getters.salonAcceptsChildren()">
						{{ $t("common.youth") }}
					</th>
					<th colspan="1">
						{{ $t("common.wom") }}
					</th>
					<th colspan="1">
						{{ $t("common.men") }}
					</th>
					<th colspan="1" rowspan="1" v-if="$store.getters.salonAcceptsChildren()">
						{{ $t("common.youth") }}
					</th>
				</tr>
			</thead>
			<tbody>
				<template v-for="formula in pack">
					<tr
						:class="['row-border-top', activeFormulas.indexOf(formula.id) >= 0 && 'active']"
						@click="toggleFormulaActive(formula)"
					>
						<td class="max-width-1 table-name-mobile" :rowspan="formula.rowspan">{{ formula.title || formula.titleComputed }}</td>
						<td class="hair desktop" :rowspan="1" :class="formula.oneline ? 'mobile-none' : ''" v-if="$store.getters.salonPrestationsHasLength()">
							{{ formula.oneline ? '' : $t('data.hairlength.short') }}
						</td>
						<FormulaMatrixMobile class="mobile" :formula="formula"></FormulaMatrixMobile>
						<template v-if="formula.female_">
							<td v-if="formula.price_female != null" class="td-small cell-f desktop" :rowspan="formula.rowspan">
								{{ formula.price_female }}
							</td>
							<td v-else class="td-small cell-f desktop" :rowspan="1">
								<template v-if="formula.female_short_price">{{ formula.female_short_price }}</template>
								<i v-else class="text-muted">{{ calculatePrice( formula, domain == 'coachs' ? 'female' : 'female_short' ) }}</i>
							</td>
						</template>
						<td v-else class="td-small cell-f desktop"/>
						<template v-if="formula.male_">
							<td v-if="formula.price_male != null" class="td-small cell-h desktop" :rowspan="formula.rowspan">
								{{ formula.price_male }}
							</td>
							<td v-else class="td-small cell-h desktop" :rowspan="1">
								<template v-if="formula.male_short_price">{{ formula.male_short_price }}</template>
								<i v-else class="text-muted">{{ calculatePrice( formula, domain == 'coachs' ? 'male' : 'male_short' ) }}</i>
							</td>
						</template>
						<td v-else class="td-small cell-h desktop"/>
						<td v-if="formula.child_ && $store.getters.salonAcceptsChildren()" class="td-small cell-enf desktop" :rowspan="formula.rowspan">
							<template v-if="formula.child_price">{{ formula.child_price }}</template>
							<i v-else class="text-muted">{{ calculatePrice( formula, 'child' ) }}</i>
						</td>
						<td v-else-if="$store.getters.salonAcceptsChildren()" class="td-small cell-c background-child desktop" :rowspan="formula.rowspan" />




									<template v-if="formula.female_">
										<td v-if="formula.duration_female != null" class="td-small cell-f desktop" :rowspan="formula.rowspan">
											{{ formula.duration_female }}
										</td>
										<td v-else class="td-small cell-f desktop" :rowspan="1">
											<template v-if="formula.female_short_time">{{ formula.female_short_time }}</template>
											<i v-else class="text-muted">{{ calculateDuration( formula, domain == 'coachs' ? 'female' : 'female_short' ) }}</i>
										</td>
									</template>
									<td v-else class="td-small cell-f desktop"/>
									<template v-if="formula.male_">
										<td v-if="formula.duration_male != null" class="td-small cell-h desktop" :rowspan="formula.rowspan">
											{{ formula.duration_male }}
										</td>
										<td v-else class="td-small cell-h desktop" :rowspan="1">
											<template v-if="formula.male_short_time">{{ formula.male_short_time }}</template>
											<i v-else class="text-muted">{{ calculateDuration( formula, domain == 'coachs' ? 'male' : 'male_short' ) }}</i>
										</td>
									</template>
									<td v-else class="td-small cell-h desktop"/>
									<td v-if="formula.child_ && $store.getters.salonAcceptsChildren()" class="td-small cell-enf desktop" :rowspan="formula.rowspan">
										<template v-if="formula.child_time">{{ formula.child_time }}</template>
										<i v-else class="text-muted">{{ calculateDuration( formula, 'child' ) }}</i>
									</td>
									<td v-else-if="$store.getters.salonAcceptsChildren()" class="td-small cell-c background-child desktop" :rowspan="formula.rowspan" />




						<td class="mobile">{{$t("common.onlyfor")}}</td>
						<td :rowspan="formula.rowspan">
							<div class="checkbox checkbox-primary">
								<input :checked="formula.female_" class=" styled d-none" disabled name="create_f" type="checkbox">
								<label class="d-inline-block" for="create_f_">
									<div class="checkbox-text">{{$t("common.wom")}}</div>
								</label>
							</div>
							<div class="checkbox checkbox-primary">
								<input :checked="formula.male_" class=" styled d-none" disabled name="create_h" type="checkbox">
								<label class="d-inline-block" for="create_h_">
								<div class="checkbox-text">{{$t("common.men")}}</div>
								</label>
							</div>
							<div class="checkbox checkbox-primary" v-if="$store.getters.salonAcceptsChildren()">
								<input :checked="formula.child_" class=" styled d-none" disabled name="create_enf" type="checkbox">
								<label class="d-inline-block" for="create_enf_">
								<div class="checkbox-text">{{$t("common.youth")}}</div>
								</label>
							</div>
						</td>
						<th class="mobile">{{ $t("common.services") }}</th>
						<td :rowspan="formula.rowspan">
							<div v-for="service in ( formula.computedServices || [] ).filter( s => !s.isDependency )">{{ service.title }}</div>
							<div v-for="service in ( formula.computedServices || [] ).filter( s => !!s.isDependency )" class="dependency">{{ service.title }}</div>
						</td>
						<td class="td-button mobile-100" :rowspan="formula.rowspan">
							<button class="btn-mod fas fa-edit btn btn-outline-success" @click="editingFormula = formula" type="button">
						</button></td>
					</tr>


					<tr v-if="!formula.oneline && $store.getters.salonPrestationsHasLength()" :key="formula.id + '-2'" class="desktop">
						<td class="hair">
							{{ $t('data.hairlength.half') }}
						</td>
						<td v-if="formula.female_ && formula.price_female == null" class="td-small cell-f" rowspan="1">
							<template v-if="formula.female_half_price">{{ formula.female_half_price }}</template>
							<i v-else class="text-muted">{{ calculatePrice( formula, 'female_half' ) }}</i>
						</td>
						<td v-else class="td-small cell-f"/>
						<td v-if="formula.price_male == null" class="td-small cell-h" rowspan="1" />

						<td v-if="formula.female_ && formula.duration_female == null" class="td-small cell-f" rowspan="1">
							<template v-if="formula.female_half_time">{{ formula.female_half_time }}</template>
							<i v-else class="text-muted">{{ calculateDuration( formula, 'female_half' ) }}</i>
						</td>
						<td v-else class="td-small cell-f"/>
						<td v-if="formula.duration_male == null" class="td-small cell-h" rowspan="1" />

					</tr>
					<tr v-if="!formula.oneline && $store.getters.salonPrestationsHasLength()" :key="formula.id + '-3'" class="desktop">
						<td class="hair">
							{{ $t('data.hairlength.long') }}
						</td>
						<td v-if="formula.female_ && formula.price_female == null" class="td-small cell-f" rowspan="1">
							<template v-if="formula.female_long_price">{{ formula.female_long_price }}</template>
							<i v-else class="text-muted">{{ calculatePrice( formula, 'female_long' ) }}</i>
						</td>
						<td v-else class="td-small cell-f"/>
						<td v-if="formula.male_ && formula.price_male == null" class="td-small cell-h" rowspan="1">
							<template v-if="formula.male_long_price">{{ formula.male_long_price }}</template>
							<i v-else class="text-muted">{{ calculatePrice( formula, 'male_long' ) }}</i>
						</td>
						<td v-else class="td-small cell-h"/>

						<td v-if="formula.female_ && formula.duration_female == null" class="td-small cell-f" rowspan="1">
							<template v-if="formula.female_long_time">{{ formula.female_long_time }}</template>
							<i v-else class="text-muted">{{ calculateDuration( formula, 'female_long' ) }}</i>
						</td>
						<td v-else class="td-small cell-f"/>
						<td v-if="formula.male_ && formula.duration_male == null" class="td-small cell-h" rowspan="1">
							<template v-if="formula.male_long_time">{{ formula.male_long_time }}</template>
							<i v-else class="text-muted">{{ calculateDuration( formula, 'male_long' ) }}</i>
						</td>
						<td v-else class="td-small cell-h"/>

					</tr>
				</template>
				<tr id="btn-add">
					<td class="td-button add-presta mobile-100" colspan="12">
						<button id="add-formula" class="btn-add fas fa-plus btn btn-primary" v-on:click="editingFormula = {}" type="button"></button>
					</td>
				</tr>
			</tbody>
		</table>
		<EditPack v-if="editingFormula" :data="editingFormula" v-on:close="editingFormula = null"/>
	</div>
</template>

<script>
	import EditPack from '../../modals/EditPack.vue'
	import FormulaMatrixMobile from './FormulaMatrixMobile.vue'
	import { computeServices } from '../../servicesLogic.js'
	
	export default {
		components: {
			EditPack,
			FormulaMatrixMobile
		},
		data() {
			return {
				editingFormula: null,
				activeFormulas: []
			}
		},
		computed: {
			domain() {
				return this.$store.state.domain
			},
			pack() {
				let formulas = this.$store.getters.getFormulas()
				formulas = formulas.sort( ( a, b ) => a.id - b.id )
				if ( this.$store.state.domain == "esthetics" ) {
					for ( let i = 0; i < formulas.length; i++ ) {
						let formula = formulas[i]
						if ( formula.services ) {
							formula.computedServices = computeServices( this.$store.state.services, formula.services )
							formula.computedServices = formula.computedServices.sort( ( a, b ) => a.priority > b.priority )
							if ( formula.title == null || formula.title.length == 0 ) {
								let title = []
								for ( let j = 0; j < formula.computedServices.length; j++ ) {
									title.push( formula.computedServices[j].title )
								}
								formula.titleComputed = title.join( " - " )
							}
						}
						if ( formula.genders ) {
							let genders = formula.genders.split(",")
							formula.female_ = genders.includes( "female" )
							formula.male_ = genders.includes( "male" )
							formula.child_ = genders.includes( "child" )
						}
						var same_price_female = ( formula.female_short == formula.female_half && formula.female_short == formula.female_long )
						same_price_female = same_price_female && ( this.calculatePrice(formula, "female_short") == this.calculatePrice(formula, "female_half") && this.calculatePrice(formula, "female_short") == this.calculatePrice(formula, "female_long") )
						var same_price_male = ( formula.male_short == formula.male_long ); // no half for men
						same_price_male = same_price_male && ( this.calculatePrice(formula, "male_short") == this.calculatePrice(formula, "male_long") )
						var same_duration_female = ( formula.dur_female_short == formula.dur_female_half && formula.dur_female_short == formula.dur_female_long )
						same_duration_female = same_duration_female && ( this.calculatePrice(formula, "female_short") == this.calculatePrice(formula, "female_half") && this.calculatePrice(formula, "female_short") == this.calculatePrice(formula, "female_long") )
						var same_duration_male = ( formula.dur_male_short == formula.dur_male_long ); // no half for men
						same_duration_male = same_duration_male && ( this.calculatePrice(formula, "male_short") == this.calculatePrice(formula, "male_long") )
						if ( formula.female_short ) {
							formula.female_short_price = formula.female_short + "€";
						}
						if ( formula.male_short ) {
							formula.male_short_price = formula.male_short + "€";
						}
						if ( formula.female_half ) {
							formula.female_half_price = formula.female_half + "€";
						}
						if ( formula.male_half ) {
							formula.male_half_price = formula.male_half + "€";
						}
						if ( formula.female_long ) {
							formula.female_long_price = formula.female_long + "€";
						}
						if ( formula.male_long ) {
							formula.male_long_price = formula.male_long + "€";
						}
						if ( formula.child ) {
							formula.child_price = formula.child + "€";
						}
						if ( formula.dur_female_short ) {
							formula.female_short_time = formula.dur_female_short + " min";
						}
						if ( formula.dur_male_short ) {
							formula.male_short_time = formula.dur_male_short + " min";
						}
						if ( formula.dur_female_half ) {
							formula.female_half_time = formula.dur_female_half + " min";
						}
						if ( formula.dur_male_half ) {
							formula.male_half_time = formula.dur_male_half + " min";
						}
						if ( formula.dur_female_long ) {
							formula.female_long_time = formula.dur_female_long + " min";
						}
						if ( formula.dur_male_long ) {
							formula.male_long_time = formula.dur_male_long + " min";
						}
						if ( formula.dur_child ) {
							formula.child_time = formula.dur_child + " min";
						}
						if ( same_price_female && formula.female_short /*!== undefined && formula.female_short !== null*/ ) {
							formula.price_female = formula.female_short + "€";
						}
						if ( same_price_male && formula.male_short /*!== undefined && formula.male_short !== null*/ ) {
							formula.price_male = formula.male_short + "€";
						}
						if ( same_duration_female && formula.dur_female_short /*!== undefined && formula.dur_female_short !== null*/ ) {
							formula.duration_female = formula.dur_female_short + " min";
						}
						if ( same_duration_male && formula.dur_male_short /*!== undefined && formula.dur_male_short !== null*/ ) {
							formula.duration_male = formula.dur_male_short + " min";
						}
						formula.oneline = ( same_price_female && same_price_male )
						formula.onelinePrice = ( same_price_female && same_price_male )
						formula.onelineDuration = ( same_duration_female && same_duration_male )
						formula.rowspan = formula.oneline ? 1 : 3
						formula.rowspanPrice = formula.onelinePrice ? 1 : 3
						formula.rowspanDuration = formula.onelineDuration ? 1 : 3

						if (!this.$store.getters.salonPrestationsHasLength()) {
							formula.rowspan = 1
							formula.rowspanPrice = 1
							formula.rowspanDuration = 1
						}
					}
				} else if ( this.$store.state.domain == "coachs" ) {
					for ( let i = 0; i < formulas.length; i++ ) {
						const formula = formulas[i]
						if ( formula.services ) {
							formula.computedServices = computeServices( this.$store.state.services, formula.services )
							formula.computedServices = formula.computedServices.sort( ( a, b ) => a.priority > b.priority )
							if ( formula.title == null || formula.title.length == 0 ) {
								let title = []
								for ( let j = 0; j < formula.computedServices.length; j++ ) {
									title.push( formula.computedServices[j].title )
								}
								formula.titleComputed = title.join( " - " )
							}
						}
						if ( formula.genders ) {
							let genders = formula.genders.split(",")
							formula.female_ = genders.includes( "female" )
							formula.male_ = genders.includes( "male" )
							formula.child_ = genders.includes( "child" )
						}
						console.log( "formula", i, formula )
						formula.oneline = true
						if ( formula.female ) {
							formula.price_female = formula.female + "€"
						}
						if ( formula.male ) {
							formula.price_male = formula.male + "€"
						}
						if ( formula.dur_female ) {
							formula.duration_female = formula.dur_female + " min"
						}
						if ( formula.dur_male ) {
							formula.duration_male = formula.dur_male + " min"
						}
					}
				}
				return formulas
			}
		},
		methods: {
			calculatePrice( formula, gender_hair ) {
				return formula.computedServices.reduce( (accum, s) => accum + parseFloat(s && s[gender_hair] ? (s[gender_hair][0] || 0) : 0), 0 ) + "€"
			},
			calculateDuration( formula, gender_hair ) {
				return formula.computedServices.reduce( (accum, s) => accum + parseFloat(s && s[gender_hair] ? (s[gender_hair][1] || 0) : 0), 0 ) + " min"
			},
			toggleFormulaActive({ id }) {
				const index = this.activeFormulas.indexOf(id)
				if (index >= 0)
					this.activeFormulas.splice(index)
				else
					this.activeFormulas.push(id)
			}
		}
	}
</script>
