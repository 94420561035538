<template>
	<table>
		<thead>
			<tr>
				<th :colspan="($store.getters.salonAcceptsChildren() ? 3 : 2) + !!$store.getters.salonPrestationsHasLength()">
					{{ $t("common.price") }}
				</th>
			</tr>
			<tr>
				<th rowspan="2" v-if="$store.getters.salonPrestationsHasLength()">
					{{ $t("common.hair") }}
				</th>
				<th
					colspan="1"
					:style="{ width: !$store.getters.salonPrestationsHasLength() && !$store.getters.salonAcceptsChildren() ? '50%' : null }"
				>
					{{ $t("common.wom") }}
				</th>
				<th colspan="1">
					{{ $t("common.men") }}
				</th>
				<th colspan="1" rowspan="1" v-if="$store.getters.salonAcceptsChildren()">
					{{ $t("common.youth") }}
				</th>
			</tr>
		</thead>
		<tbody>
			<tr :key="formula.id + '-1'" class="row-border-top">
				<td class="hairs" :rowspan="1" v-if="$store.getters.salonPrestationsHasLength()">
					{{ formula.onelinePrice ? $t('common.all') : $t('data.hairlength.short') }}
				</td>
				<template v-if="formula.female_">
					<td v-if="formula.price_female != null" class="td-small cell-f" :rowspan="formula.rowspanPrice">
						{{ formula.price_female }}
					</td>
					<td v-else class="td-small cell-f" :rowspan="1">
						<template v-if="formula.female_short_price">{{ formula.female_short_price }}</template>
						<i v-else class="text-muted">{{ calculatePrice( formula, domain == 'coachs' ? 'female' : 'female_short' ) }}</i>
					</td>
				</template>
				<td v-else class="td-small cell-f"/>
				<template v-if="formula.male_">
					<td v-if="formula.price_male != null" class="td-small cell-h" :rowspan="formula.rowspanPrice">
						{{ formula.price_male }}
					</td>
					<td v-else class="td-small cell-h" :rowspan="1">
						<template v-if="formula.male_short_price">{{ formula.male_short_price }}</template>
						<i v-else class="text-muted">{{ calculatePrice( formula, domain == 'coachs' ? 'male' : 'male_short' ) }}</i>
					</td>
				</template>
				<td v-else class="td-small cell-h"/>
				<template v-if="formula.child_ && $store.getters.salonAcceptsChildren()">
					<td class="td-small cell-enf" :rowspan="formula.rowspanPrice">
						<template v-if="formula.child_price">{{ formula.child_price }}</template>
						<i v-else class="text-muted">{{ calculatePrice( formula, 'child' ) }}</i>
					</td>
				</template>
				<td v-else-if="$store.getters.salonAcceptsChildren()" class="td-small cell-c background-child" :rowspan="formula.rowspanPrice"/>
			</tr>
			<tr v-if="!formula.onelinePrice && $store.getters.salonPrestationsHasLength()" :key="formula.id + '-2'">
				<td class="hairs">
					{{ $t('data.hairlength.half') }}
				</td>
				<template v-if="formula.female_">
					<td v-if="formula.price_female == null" class="td-small cell-f" rowspan="1">
						<template v-if="formula.female_half_price">{{ formula.female_half_price }}</template>
						<i v-else class="text-muted">{{ calculatePrice( formula, 'female_half' ) }}</i>
					</td>
				</template>
				<td v-else class="td-small cell-f"/>
				<template v-if="formula.male_">
					<td v-if="formula.price_male == null" class="td-small cell-h" rowspan="1" />
				</template>
				<td v-else class="td-small cell-h"/>
			</tr>
			<tr v-if="!formula.onelinePrice && $store.getters.salonPrestationsHasLength()" :key="formula.id + '-3'">
				<td class="hairs">
					{{ $t('data.hairlength.long') }}
				</td>
				<template v-if="formula.female_">
					<td v-if="formula.price_female == null" class="td-small cell-f" rowspan="1">
						<template v-if="formula.female_long_price">{{ formula.female_long_price }}</template>
						<i v-else class="text-muted">{{ calculatePrice( formula, 'female_long' ) }}</i>
					</td>
				</template>
				<td v-else class="td-small cell-f"/>
				<template v-if="formula.male_">
					<td v-if="formula.price_male == null" class="td-small cell-h" rowspan="1">
						<template v-if="formula.male_long_price">{{ formula.male_long_price }}</template>
						<i v-else class="text-muted">{{ calculatePrice( formula, 'male_long' ) }}</i>
					</td>
				</template>
				<td v-else class="td-small cell-h"/>
			</tr>
		</tbody>




		<thead>
			<tr>
				<th :colspan="($store.getters.salonAcceptsChildren() ? 3 : 2) + !!$store.getters.salonPrestationsHasLength()">
					{{ $t("common.durate") }}
				</th>
			</tr>
			<tr>
				<th rowspan="2" v-if="$store.getters.salonPrestationsHasLength()">
					{{ $t("common.hair") }}
				</th>
				<th
					colspan="1"
					:style="{ width: !$store.getters.salonPrestationsHasLength() && !$store.getters.salonAcceptsChildren() ? '50%' : null }"
				>
					{{ $t("common.wom") }}
				</th>
				<th colspan="1">
					{{ $t("common.men") }}
				</th>
				<th colspan="1" rowspan="1" v-if="$store.getters.salonAcceptsChildren()">
					{{ $t("common.youth") }}
				</th>
			</tr>
		</thead>
		<tbody>
			<tr :key="formula.id + '-1'" class="row-border-top">
				<td class="hairs" :rowspan="1" v-if="$store.getters.salonPrestationsHasLength()">
					{{ formula.onelineDuration ? $t('common.all') : $t('data.hairlength.short') }}
				</td>
				<template v-if="formula.female_">
					<td v-if="formula.dur_female != null" class="td-small cell-f" :rowspan="formula.rowspanPrice">
						{{ formula.dur_female }}
					</td>
					<td v-else class="td-small cell-f" :rowspan="1">
						<template v-if="formula.female_short_time">{{ formula.female_short_time }}</template>
						<i v-else class="text-muted">{{ calculateDuration( formula, domain == 'coachs' ? 'female' : 'female_short' ) }}</i>
					</td>
				</template>
				<td v-else class="td-small cell-f"/>
				<template v-if="formula.male_">
					<td v-if="formula.dur_male != null" class="td-small cell-h" :rowspan="formula.rowspanPrice">
						{{ formula.dur_male }}
					</td>
					<td v-else class="td-small cell-h" :rowspan="1">
						<template v-if="formula.male_short_time">{{ formula.male_short_time }}</template>
						<i v-else class="text-muted">{{ calculateDuration( formula, domain == 'coachs' ? 'female' : 'male_short' ) }}</i>
					</td>
				</template>
				<td v-else class="td-small cell-h"/>
				<template v-if="formula.child_ && $store.getters.salonAcceptsChildren()">
					<td class="td-small cell-enf" :rowspan="formula.rowspanPrice">
						<template v-if="formula.child_time">{{ formula.child_time }}</template>
						<i v-else class="text-muted">{{ calculateDuration( formula, 'child' ) }}</i>
					</td>
				</template>
				<td v-else-if="$store.getters.salonAcceptsChildren()" class="td-small cell-c background-child" :rowspan="formula.rowspanPrice"/>
			</tr>
			<tr v-if="!formula.onelineDuration && $store.getters.salonPrestationsHasLength()" :key="formula.id + '-2'">
				<td class="hairs">
					{{ $t('data.hairlength.half') }}
				</td>
				<template v-if="formula.female_">
					<td v-if="formula.dur_female == null" class="td-small cell-f" rowspan="1">
						<template v-if="formula.female_half_time">{{ formula.female_half_time }}</template>
						<i v-else class="text-muted">{{ calculateDuration( formula, 'female_half' ) }}</i>
					</td>
				</template>
				<td v-else class="td-small cell-f"/>
				<template v-if="formula.male_">
					<td v-if="formula.dur_male == null" class="td-small cell-h" rowspan="1" />
				</template>
				<td v-else class="td-small cell-h"/>
			</tr>
			<tr v-if="!formula.onelineDuration && $store.getters.salonPrestationsHasLength()" :key="formula.id + '-3'">
				<td class="hairs">
					{{ $t('data.hairlength.long') }}
				</td>
				<template v-if="formula.female_">
					<td v-if="formula.dur_female == null" class="td-small cell-f" rowspan="1">
						<template v-if="formula.female_long_time">{{ formula.female_long_time }}</template>
						<i v-else class="text-muted">{{ calculateDuration( formula, 'female_long' ) }}</i>
					</td>
				</template>
				<td v-else class="td-small cell-f"/>
				<template v-if="formula.male_">
					<td v-if="formula.dur_male == null" class="td-small cell-h" rowspan="1">
						<template v-if="formula.male_long_time">{{ formula.male_long_time }}</template>
						<i v-else class="text-muted">{{ calculateDuration( formula, 'male_long' ) }}</i>
					</td>
				</template>
				<td v-else class="td-small cell-h"/>
			</tr>
		</tbody>
	</table>
</template>

<script>

export default {
	props: [
		"formula"
	],
	data() {
		return {}
	},
	computed: {
		domain() {
			return this.$store.state.domain
		}
	},
	methods: {
		calculatePrice( formula, gender_hair ) {
			console.log( "calculatePrice", gender_hair )
			return formula.computedServices.reduce( (accum, s) => accum + parseFloat(s && s[gender_hair] ? (s[gender_hair][0] || 0) : 0), 0 ) + "€"
		},
		calculateDuration( formula, gender_hair ) {
			console.log( "calculateDuration", gender_hair )
			return formula.computedServices.reduce( (accum, s) => accum + parseFloat(s && s[gender_hair] ? (s[gender_hair][1] || 0) : 0), 0 ) + " min"
		}
	}
};
</script>

